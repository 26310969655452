@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.otf') format('opentype');
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  src: url('./assets/fonts/Roboto-Regular.woff') format('woff');
  src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.otf') format('opentype');
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  src: url('./assets/fonts/Roboto-Bold.woff') format('woff');
  src: url('./assets/fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
}
